import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { isEmpty } from "lodash"

const LightTeamMember = ({ member, index }) => {
  const image = getImage(member.avatar)

  return (
    <div data-sal="slide-up" data-sal-delay={index * 100} data-sal-duration="1000" key={index} className="font-serif w-full">
      <Link to={`/` + member.slug}>
        <GatsbyImage image={image} alt={member.firstname + ` ` + member.lastname} />
      </Link>
      <Link to={`/` + member.slug}>
        <p className="text-black text-2xl pt-5 pb-2">
          {member.firstname + ` ` + member.lastname}
        </p>
      </Link>
      <div className="flex items-baseline justify-items-stretch">
        <span className="text-purple">{member.role}</span>
        <div className="flex items-baseline px-2 lg:px-10">
          <a className={isEmpty(member.email) ? `hidden invisible` : `visible px-2 flex items-center`} rel="noreferrer" target="_blank" href={"mailto:" + member.email}>
            <StaticImage
              src={"../images/mail.png"}
              width={15}
              formats={["auto", "webp", "avif"]}
              alt="email"
              objectFit="contain"
            />
          </a>
          <a className={isEmpty(member.linkedin) ? `hidden invisible` : `visible px-2 flex items-center`} rel="noreferrer" target="_blank" href={member.linkedin}>
            <StaticImage
              src={"../images/linkedin.png"}
              width={15}
              formats={["auto", "webp", "avif"]}
              alt="Linkedin"
            />
          </a>
          <a
            className={isEmpty(member.twitter) ? `hidden invisible` : `visible px-2 flex items-center`} rel="noreferrer" target="_blank" href={member.twitter}>
            <StaticImage
              src={"../images/twitter.png"}
              width={15}
              formats={["auto", "webp", "avif"]}
              alt="Twitter"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default LightTeamMember
