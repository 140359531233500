import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import LightTeamMember from "../components/lightTeamMember"
import Seo from "../components/seo"
import Layout from "../components/layout"

const Team = ({ data }) => {
  const teamContentful = data?.allContentfulTeamMember?.nodes
  const teamPageContentful = data?.allContentfulTeamPage?.nodes[0]

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <b className="font-bold">{text}</b>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        return (
          <a href={uri} className="underline">
            {children}
          </a>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2>{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3>{children}</h3>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="whitespace-pre-line">
          {children}
        </p>
      ),
    },
  }

  return (
    <Layout>
      <Seo title={"Our team"} description={"Team of Dryline Partners"} />
      <div className="container flex items-start mx-auto pb-32">
        <div className="bg-white p-5 lg:py-20">
          <div className="container p-5 relative">
            <h1 data-sal="slide-left" data-sal-duration="1000" className="text-purple pb-20">
              {teamPageContentful.subtitle}
            </h1>
            <div className="right-64 -z-5 -top-20 absolute">
              <StaticImage
                src="../images/team.png"
                width={400}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Team"
              />
            </div>
            <h2 data-sal="slide-left" data-sal-duration="1000" data-sal-delay="200" className="text-3xl lg:text-5xl font-bold text-black">
             {teamPageContentful.title}
            </h2>
            <div data-sal="slide-left" data-sal-duration="1000" data-sal-delay="300"  className="font-serif py-10 text-black text-xl max-w-lg">
              {renderRichText(teamPageContentful.description, options)}
            </div>
            <div className="">
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-24">
                {teamContentful?.map((member, index) => (
                  <LightTeamMember member={member} index={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Team

export const pageQuery = graphql`
  query TeamQuery {
    allContentfulTeamPage {
      nodes {
        title
        subtitle
        description {
          raw
        }
      }
    }
    allContentfulTeamMember(sort: { fields: order }) {
      nodes {
        id
        firstname
        email
        lastname
        linkedin
        twitter
        email
        role
        slug
        avatar {
          gatsbyImageData(width: 400)
        }
      }
    }
  }
`
